import { getAppToken } from '@adas/shared-types'
import { ApolloClient, ApolloLink, InMemoryCache, ServerError, ServerParseError } from '@apollo/client'
import { RestLink } from 'apollo-link-rest'
import { onError } from '@apollo/client/link/error'

const ERROR_CODE_UNAUTHENTICATED = '401'

const checkStatusCode = (networkError: Error | ServerError | ServerParseError, code: string) => networkError.toString().indexOf(code) !== -1

const authRestLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }: { headers: Record<string, string> }) => {
        const appToken = getAppToken()
        return {
            headers: {
                ...headers,
                Accept: 'application/json',
                Authorization: `Bearer ${appToken ?? ''}`,
            },
        }
    })
    return forward(operation).map((result) => result)
})

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path?.toString() ?? ''}`),
        )
    }
    if (networkError) {
        if (checkStatusCode(networkError, ERROR_CODE_UNAUTHENTICATED)) {
            localStorage.removeItem('appToken')
        } else {
            console.log('[Network error]:', networkError)
        }
    }
})

const { protocol, host } = window.location
const baseURL = process.env.REACT_APP_API_URL ?? `${protocol}//${host}/`

const restLink = new RestLink({ uri: baseURL })

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authRestLink, errorLink, restLink]),
})

export default client
